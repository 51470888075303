<template>
  <div id="app" class="tickets">
    <!-- Loading Spinner -->
    <div class="spinner" v-if="isLoading">
      <div><div></div></div>
    </div>
    <!-- Header -->
    <section class="head">
      <div class="bg"></div>
      <div class="left">
        <a @click.prevent="goTo('/home')">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span>優惠券兌換紀錄</span>
      <div class="right"></div>
    </section>
    <section class="main">
      <div class="pages">
        <!-- <a
          :class="['page', { active: statusFilter === 'pickedup' }]"
          @click.prevent="statusFilter = 'pickedup'"
        >
          <span>可使用</span>
        </a> -->
        <a
          :class="['page', { active: statusFilter === 'redeemed' }]"
          @click.prevent="statusFilter = 'redeemed'"
        >
          <span>已兌換</span>
        </a>
        <a
          :class="['page', { active: statusFilter === 'expired' }]"
          @click.prevent="statusFilter = 'expired'"
        >
          <span>已過期</span>
        </a>
      </div>
      <!-- <div class="filter">
        <div class="search">
          <input type="text" placeholder="關鍵字搜尋" v-model="searchTerm" />
          <a
            ><font-awesome-icon
              icon="fa-solid fa-magnifying-glass"
              size="sm"
            ></font-awesome-icon
          ></a>
        </div>
      </div> -->
      <div v-if="initialized && showNoTicket" class="none">
        <img src="@/assets/icon/mTicket.svg" />
        <span>無紀錄</span>
      </div>
      <a
        v-else-if="initialized"
        class="coupon"
        v-for="(ticket, index) in displayedTickets"
        :key="'ticket_' + index"
        @click.prevent="goTo(`/tickets/${ticket.ticketId}`)"
      >
        <div class="dot left"></div>
        <div class="dot right"></div>
        <div class="label" v-if="ticket.status === 'redeemed'">
          <span>已兌換</span>
        </div>
        <div class="label" v-else-if="ticket.status === 'expired'">
          <span>已過期</span>
        </div>
        <div class="content">
          <div>
            <span class="title">{{ ticket.title }}</span>
            <span class="name">{{ ticket.storeName }}</span>
          </div>
          <span class="info" v-html="ticket.description"></span>
          <span class="date"
            >{{ tsToDate(ticket.useStartAt) }} -
            {{ tsToDate(ticket.useExpirationAt) }}</span
          >
        </div>
        <img v-if="ticket.image" :src="ticket.image" />
      </a>
    </section>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';

export default {
  name: 'MyTickets',
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      isLoading: false,
      originTickets: [],
      tickets: [],
      searchTerm: '',
      categories: [],
      categoryFilter: null,
      statusFilter: 'redeemed',
      ecUrl: null,
      categoryFilterPopOpen: false,
      initialized: false,
    };
  },
  mounted() {
    this.getTickets();
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    merchantId() {
      return this.user.user?._json?.merchantId || this.userInfo.merchantId;
    },
    // matchingTickets() {
    //   return this.tickets.filter(
    //     (ticket) =>
    //       ticket.status === this.statusFilter &&
    //       ticket.storeName.toLowerCase().includes(this.searchTerm.toLowerCase())
    //   );
    // },
    displayedTickets() {
      return this.tickets.filter(ticket => ticket.status === this.statusFilter);
    },
    showNoTicket() {
      return !this.displayedTickets.length;
    },
  },
  methods: {
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD');
    },
    dummyTickets() {
      let result = [
        {
          merchantId: 'tycard2022',
          saleOrderId: '0000022131',
          productId: 1,
          name: '【50嵐】10元折價券（限桃園地區分店）',
          title: '【50嵐】10元折價券（限桃園地區分店）',
          description: '使用此券可折抵任一飲品10元',
          storeId: 7,
          images: [],
          storeName: 'GP健康定食創意料理店家',
          storeCategoryId: 1,
          storeCategory: '餐飲',
          productType: 'ticket',
          useStartAt: null,
          useExpirationAt: null,
          createdTs: 1664761721,
          tickets: [
            {
              ticketId: 6,
              barcodeMessage: 'AAM654003488393',
              status: 'redeemed',
              updatedTs: 1664761722,
            },
            {
              ticketId: 7,
              barcodeMessage: 'AAM654003496576',
              status: 'redeemed',
              updatedTs: 1664761891,
            },
          ],
        },
        {
          merchantId: 'tycard2022',
          saleOrderId: '0000022132',
          productId: 2,
          name: '【yoxi】50元乘車金兌換券',
          title: '【yoxi】50元乘車金兌換券',
          description: '可使用此券兌換一組50元乘車金序號',
          storeId: 6,
          images: [],
          storeName: 'Miss Energy',
          storeCategoryId: 1,
          storeCategory: '餐飲',
          productType: 'ticket',
          useStartAt: null,
          useExpirationAt: null,
          createdTs: 1664761891,
          tickets: [
            {
              ticketId: 8,
              barcodeMessage: 'AAMV552R817VZPR',
              status: 'expired',
              updatedTs: 1664761891,
            },
          ],
        },
      ];
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callGetTicketsAPI() {
      let url = `${this.apiHost}/tickets/v1/merchants/${this.merchantId}/members/${this.user.userId}/tickets`;
      var config = {
        method: 'get',
        url: url,
        headers: {},
      };
      return this.$http(config);
    },
    getTickets() {
      // this.isLoading = true;
      this.callGetTicketsAPI()
        // this.dummyTickets()
        .then(res => {
          console.log('getTicketsRes', res);
          this.originTickets = res.data;
          this.tickets = res.data
            .reduce((acc, product) => {
              const tickets = product.tickets.map(ticket => {
                const ticketCopy = { ...ticket };
                ticketCopy.title = product.title;
                ticketCopy.description = product.description;
                ticketCopy.storeName = product.storeName;
                ticketCopy.image = product.images[0];
                ticketCopy.storeCategory = product.storeCategory;
                ticketCopy.useStartAt = product.useStartAt;
                ticketCopy.useExpirationAt = product.useExpirationAt;
                ticketCopy.updatedAt = ticket.updatedTs || ticket.createdTs;
                return ticketCopy;
              });
              return (acc = [...acc, ...tickets]);
            }, [])
            .filter(t => t.status !== 'pickedup')
            .sort((a, b) => a.updatedAt - b.updatedAt);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.initialized = true;
          // this.isLoading = false
        });
    },
  },
};
</script>
